import styled from 'styled-components'
import { useEffect, useMemo, useRef } from 'react'
import { useActions } from 'components/hooks/useActions'

import cross from 'images/icons/cross-white.svg'
import errorIcon from 'images/icons/error.svg'

const CloseButton = styled.button`
  width: 24px;
  height: 24px;
  background-color: inherit;
  cursor: pointer;
  transition: opacity 0.26s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`

const NotificationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: ${({ type }) =>
    type === 'error' ? '#BB4126' : 'var(--primary-1)'};
  transition: transform 0.35s ease-in-out, opacity 0.35s ease-in-out;
  transform: translateX(150%);

  &.notification-enter {
    transform: translateX(150%);
    opacity: 0;
  }

  &.notification-enter-active,
  &.notification-enter-done {
    opacity: 1;
    transform: translateX(0);
  }

  &.notification-exit {
    opacity: 1;
    transform: translateX(0);
  }

  &.notification-exit-active {
    opacity: 0;
    transform: translateX(150%);
  }

  &.notification-exit-done {
    opacity: 0;
  }
`

const NotificationWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.4s ease-in-out;
`

const NotificationIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: ${({ color }) => color};
`

const NotificationText = styled.div`
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--white);
`

// const NotificationIcon = styled.div`
//   width: 24px;
//   height: 24px;
//   background: ${({ icon }) =>
//     `url(/_next_static/icons/${icon}.svg) no-repeat center/contain`};
// `

const typeOptions = {
  error: {
    icon: errorIcon,
    color: 'red',
    text: 'Error! ',
  },
  success: {
    icon: false,
    color: 'success',
    text: 'Success! ',
  },
  warning: {
    icon: false,
    color: 'orange',
    text: 'Attention! ',
  },
}

const Notification = ({ type, text, id }) => {
  const notificationRef = useRef(null)
  const { removeNotification } = useActions()
  const typeData = useMemo(() => {
    return typeOptions[type]
  }, [type])

  useEffect(() => {
    // notificationRef.current.addEventListener('animationend', () => {
    //   removeNotification(id)
    // })
    setTimeout(() => {
      removeNotification(id)
    }, 10000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onButtonClickHandler = (event) => {
    event.stopPropagation()
    removeNotification(id)
  }

  return (
    <NotificationContainer ref={notificationRef} type={type}>
      <NotificationWrap>
        {typeData.icon && (
          <NotificationIconWrap color={typeData.iconWrapColor}>
            <img src={typeData.icon} width="20" height="20" alt="icon" />
          </NotificationIconWrap>
        )}
        <div>
          <NotificationText>
            {typeData.text} {text}
          </NotificationText>
        </div>
      </NotificationWrap>

      <CloseButton onClick={onButtonClickHandler}>
        <img src={cross} width="20" height="20" alt="cross" />
      </CloseButton>
    </NotificationContainer>
  )
}

export default Notification
