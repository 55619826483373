import { useState } from 'react';
import styled, { keyframes } from 'styled-components'
import iconInfo from 'images/icons/info-black.svg'

const moveThumb = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(16px);
  }
`;

const moveThumbBack = keyframes`
  0% {
    transform: translateX(16px);
  }
  100% {
    transform: translateX(0);
  }
`;

const Container = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  gap: 12px;

  cursor: ${({ blocked }) => (blocked ? 'default' : 'pointer')};

  ${({ reverse }) => reverse && `
    flex-direction: row-reverse;
    justify-content: flex-end;
  `}

  ${({ styles }) => styles}
`

const Text = styled.p`
  display: inline-block;
  line-height: 20px;
  font-size: 14px;
  ${({ styles }) => styles}
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 12px;
  ${({ styles }) => styles}
`

const TumblerWrap = styled.div`
  display: flex;
  align-items: center;

  width: 36px;
  height: 20px;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 16px;

  background-color: ${({ value }) => (value ? 'var(--primary-5)' : '#6E7B98')};
  opacity: ${({ blocked }) => (blocked ? 0.5 : 1)};
  transition: background-color 0.3s ease;

  ${({ styles }) => styles}
`

const Thumb = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: #fff;

  animation: ${({ value }) => (value ? moveThumb : moveThumbBack)} 0.3s forwards;

  ${({ styles }) => styles}
`

const Tumbler = ({
  text,
  name,
  checked = false,

  slice,
  blocked = false,
  reverse,
  infoIcon,
  callbackFun,

  justify,
  width,

  containerStyles,
  rowStyles,
  textStyles,
  tumblerWrapStyles,
  thumbStyles,
}) => {
  const [toggleValue, setToggleValue] = useState(checked);

  const handleChange = () => {
    if (blocked) return
    setToggleValue(!toggleValue);
    if (callbackFun) callbackFun(!toggleValue);
  };

  const generateCSS = (elementStyles) => {
    if (!elementStyles) return

    const cssText = Object.entries(elementStyles)
      .map(([key, value]) => `${key}: ${value};`)
      .join(' ')

    return cssText
  }

  const containerCSS = generateCSS(containerStyles)
  const rowCSS = generateCSS(rowStyles)
  const textCSS = generateCSS(textStyles)
  const tumblerWrapCSS = generateCSS(tumblerWrapStyles)
  const thumbCSS = generateCSS(thumbStyles)


  return (
    <Container
      onClick={handleChange}
      reverse={reverse}
      justify={justify}
      width={width}
      blocked={blocked}
      styles={containerCSS}
    >
      <Row styles={rowCSS}>
        {slice ?
          <Text styles={textCSS}>{text?.slice(0, 7)}</Text> :
          <Text styles={textCSS}>{text}</Text>}
        {infoIcon && <img src={iconInfo} width="10" height="15" alt="info" />}
      </Row>
      <TumblerWrap
        value={toggleValue}
        blocked={blocked}
        styles={tumblerWrapCSS}
      >
        <Thumb
          value={toggleValue}
          styles={thumbCSS}
        />
      </TumblerWrap>
      <input type="checkbox" value={toggleValue} name={name} checked={toggleValue} style={{ display: 'none' }} />
    </Container>
  )
}

export default Tumbler
